import "./roxy.scss";
import React from "react";

export default ({}) => {
  return (
    <React.Fragment>
      <div className="roxy root-div">
        <div className="container">
          <header>
            <p>Roxanna E Stewart</p>
            <button type="button" className="btn btn-primary">
              Start Game!
            </button>
          </header>
        </div>
      </div>
    </React.Fragment>
  );
};
