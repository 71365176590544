import "bootstrap/dist/js/bootstrap.min.js";
import "./App.scss";
import React from "react";
import NavBar from "./components/navbar";
import RoxyHome from "./roxy/home";
// import data from './dictionary.json'
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <Routes>
        <Route path="roxy" element={<RoxyHome />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
